import React, { useEffect, useState } from 'react';
import Legend from './legend';
import { Chart } from 'chart.js';
import 'chartjs-plugin-zoom';
import "hammerjs";
import * as ChartGeo from 'chartjs-chart-geo';
import * as d3ScaleChromatic from 'd3-scale-chromatic'
import './map.css';

const countriesAlt = {
	'Marshall Is.': 'Marshall Islands',
	'São Tomé and Principe' : 'Sao Tome and Principe',
	'St. Vin. and Gren.' : 'Saint Vincent and the Grenadines',
	'St. Kitts and Nevis' : 'Saint Kitts and Nevis',
	'Macedonia': 'North Macedonia',
	'Czechia' : 'Czech Republic',
	'Côte d\'Ivoire': 'Ivory Coast',
	'Central African Rep.' : 'Central African Republic',
	'Cabo Verde': 'Cape Verde',
	'Bosnia and Herz.' : 'Bosnia and Herzegovina',
	'Armenia' : 'Armenia',
	'Antigua and Barb.' : 'Antigua and Barbuda',
	'United States' : 'United States of America',
}

const VALUES_CONSTANTS = { '-1': 0, 'VF': 0.2, 'VOA': 0.4, 'N': 0.6, 'ETA': 0.8, 'VR': 0.9, 'N/A': 1 };

const countryFilter = (d, visaInfo, countryOfOrigin) => {
	const currentCountry = d.properties.name;
	let daysAllowed = '';
	let visaValue = '';

	const country = visaInfo.filter(visa => visa[0] === countryOfOrigin && 
		(currentCountry.indexOf(visa[1]) !== -1 || 
		( countriesAlt[currentCountry] && countriesAlt[currentCountry].indexOf(visa[1]) !== -1)));

	if (countryOfOrigin === currentCountry || countryOfOrigin === [countriesAlt[currentCountry]]) {
		visaValue = VALUES_CONSTANTS['-1'];
	}
	else if (country && country[0] && isNaN(country[0][2])) {
		visaValue =  VALUES_CONSTANTS[country[0][2]];
	} else if (country && country[0]){
		visaValue = VALUES_CONSTANTS['N']
		daysAllowed = country[0][2];
	} else {
		visaValue = VALUES_CONSTANTS['N/A'];
	}
	return {feature: {...d, daysAllowed}, value: visaValue};
}


const Map = (props) => {
	const { countryOfOrigin, visaInfo, mapVisible} = props;
	const [currentChart, setCurrentChart ] = useState(null);
	useEffect(() => {
		if (mapVisible) {
			fetch('https://unpkg.com/world-atlas/countries-50m.json').then((r) => r.json()).then((data) => {
				const countries = ChartGeo.topojson.feature(data, data.objects.countries).features;
				if (currentChart) {
					currentChart.destroy();
					setCurrentChart(null);
				}
				setCurrentChart(new Chart(document.getElementById("canvas").getContext("2d"), {
					type: 'choropleth',
					data: {
						labels: countries.map((d) => d.properties.name),
						datasets: [{
							label: 'Countries',
							data: countries.map((d) => (countryFilter(d, visaInfo, countryOfOrigin))),
						}]
					},
					options: {
						tooltips: {
							callbacks: {
								label: function (toolTip, data) {
									const valuesHelper = VALUES_CONSTANTS;
									const label = Object.keys(VALUES_CONSTANTS).find(key => valuesHelper[key] === parseFloat(toolTip.value));
									if (label !== 'N' && label !== '-1') {
										return `${data.labels[toolTip.index]}: ${Object.keys(VALUES_CONSTANTS).find(key => VALUES_CONSTANTS[key] === parseFloat(toolTip.value))}`
									} else if (label === 'N') {
										return `${data.labels[toolTip.index]}: ${data.datasets[0].data[toolTip.index].feature.daysAllowed} visa-free days`;
									} else {
										return 'Your Country: 0';
									} 
								}
							}
						},
						showOutline: true,
						showGraticule: true,
						legend: {
							display: false 
						},
						scales: {
							xy: {
								projection: 'equalEarth'
							}
						},
						geo: {
							colorScale: {
								display: false,
								interpolate: d3ScaleChromatic.interpolateGnBu,
							},
						},
						pan: {
							enabled: true,
							mode: 'xy',
						},
						zoom: {
							enabled: true,
							mode: 'xy',
						},
					},
						plugins: {
						zoom: {
							pan: {
								enabled: true,
								mode: 'xy'
							},
							zoom: {
								enabled: true,
								mode: 'xy'
							}
						}
					},
				}));
			});
		}
	}, [countryOfOrigin]);

	if (!mapVisible) {
		return null;
	}
	return (
		<div>
			<canvas id="canvas" className="map-canvas"></canvas>
			<Legend />
		</div>
	)
}

export default Map;