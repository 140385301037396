import React  from 'react';
import * as d3ScaleChromatic from 'd3-scale-chromatic'

const Legend = () => {
	const mapLegend = [
		{ type: 'O', name: 'Your country', color: d3ScaleChromatic.interpolateGnBu(0) },
		{ type: 'VF', name: 'Visa Free', color: d3ScaleChromatic.interpolateGnBu(0.2) },
		{ type: 'VOA', name: 'Visa on Arrival', color: d3ScaleChromatic.interpolateGnBu(0.4) },
		{ type: '7-360', name: 'Number of visa-free days', color: d3ScaleChromatic.interpolateGnBu(0.6) },
		{ type: 'ETA', name: 'Electronic Travel Authorization', color: d3ScaleChromatic.interpolateGnBu(0.8) },
		{ type: 'VR', name: 'Visa Required', color: d3ScaleChromatic.interpolateGnBu(0.9) },
		{ type: 'N/A', name: 'Info Not Available', color: d3ScaleChromatic.interpolateGnBu(1) },
	];

	const legendField = mapLegend.map(legend => (
		<div className="col">
			<div className="" style={{ background: legend.color, width: '50px', height: '10px'}}>
			</div>
			<p className="">{legend.type + ': ' + legend.name}</p>
		</div>)
	)

	return (
		<div className="row">
			{legendField}
		</div>
	)
}

export default Legend;