import React, { useState, useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Papa from 'papaparse';
import Map from './map';
function App() {
	const [countryOfOrigin, setCountryOfOrigin] = useState('');
	const [visaInfo, setVisaInfo] = useState([]);
	const [visible, setVisible] = useState(false);
	const [countries, setCountries] = useState([]);
	let countriesSet = new Set();
	useEffect(() => {
		Papa.parse('https://raw.githubusercontent.com/ilyankou/passport-index-dataset/master/legacy/2020-05-09/passport-index-tidy.csv', {
			download: true,
			complete: function (results) {
				setVisaInfo(results.data);
				results.data.map(field => countriesSet.add(field[0])); // Optimize later
				countriesSet.delete('Passport');
				setCountries([...countriesSet]);
				setVisible(true);
			}
		});
	}, [])

	if(!visible) {
		return null;
	}
	return (
		<div className="App">
			<div className="container">
				<div className="row header align-items-center justify-content-center" style={{display: 'none'}}>
					<div className="col ">
						<span>Logo</span>
					</div>
					<div className="col">
						<span>Where to ?</span>
					</div>
					<div className="col">
						<span>About</span>
					</div>
				</div>
				<div className="row body mt-5">
					<div className="col">
						<div className="row search-section align-items-center mx-auto">
							<div className="col">
								<div className="row">
									<span className="question">Select your country of origin</span>
								</div>
								<div className="row">
									<select className="col" onChange={(e) => setCountryOfOrigin(e.target.value)}>
										{countries.sort().map(country => (<option value={country}>{country}</option>))}
									</select>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<Map countryOfOrigin={countryOfOrigin} mapVisible={countryOfOrigin} visaInfo={visaInfo} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default App;
